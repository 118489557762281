// @ts-nocheck
import DateUitl from '@utils/date';
import { isEmptyObject } from '@utils/type';
import { FormatSearchPickDate } from '@utils/format-search-date';
import { parseSearchParams } from '@utils/url';

const queryParams = parseSearchParams();
const {
    commonData: { defaultArea },
} = window.__initData;

/**
 * 将传入时间与当前时间比较
 * 取还没过期的时间
 * @param {object} pickTime - 取车时间, 
 * @param {object} dropTime - 还车时间 
 */
export function formatDate(pickTime, dropTime) {
    const now = DateUitl.formatPickDropNow();
    let defaultTime = new FormatSearchPickDate().getFormatTime();

    if (!pickTime) {
        return {
            pickTime: defaultTime.pickTime,
            dropTime: defaultTime.dropTime
        };
    } else {
        if (!dropTime) {
            dropTime = new FormatSearchPickDate({
                pickTime
            }).getFormatTime().dropTime;
        }
        // 用户记录与当前时间比对
        const historyAfterNow = DateUitl.isAfter({
            start: now,
            end: DateUitl.format(pickTime, 'YYYY/MM/DD HH:mm'),
            unit: null
        });
        // 取还车时间比对
        const diffPickDrop = DateUitl.parseDiff({
            start: pickTime,
            end: dropTime,
            unit: 'minute'
        });
        // 计算租期
        const { days } = DateUitl.diff({
            start: pickTime,
            end: dropTime,
        });
        // 若历史记录时间早于当前时间，则取还车时间使用当前时间
        if (!historyAfterNow || diffPickDrop < 30 || days < 0) {
            return {
                pickTime: defaultTime.pickTime,
                dropTime: defaultTime.dropTime
            }
        }
        return {
            pickTime,
            dropTime
        };
    }
}

export function FormatSearchParams({ pickup, dropoff, oPlace, checkList = [], special = undefined }) {
    const checkeds = checkList
        .filter(item => item.checked === true)
        .map(item => item.value);
    const ptoDoor =
        checkeds.includes('送车上门+上门取车') || checkeds.includes('送车上门')
            ? '1'
            : '0';
    const dtoDoor =
        checkeds.includes('送车上门+上门取车') || checkeds.includes('上门取车')
            ? '1'
            : '0';

    const userSelect = {
        pickup: {
            ...pickup,
        },
        dropoff: {
            ...dropoff,
            ...pickup,
        },
        oPlace: false,
        oplace: false
    };

    const params = {
        pccode: pickup.cityCode,
        pcname: pickup.cityName,
        plname: pickup.landmarkName,
        plid: pickup.landmarkId || '0',
        plandmarkType: pickup.landmarkType || '',
        plat: pickup.lat,
        plng: pickup.lng,
        pdoor: ptoDoor,
        ddoor: dtoDoor,
        ptime: pickup.pickTime,
        dtime: dropoff.dropTime,
    };
    special && (params.special = special);
    if (oPlace && pickup.landmarkName !== dropoff.landmarkName) {
        (params.dccode = dropoff.cityCode),
            (params.dcname = dropoff.cityName),
            (params.dlname = dropoff.landmarkName),
            (params.dlid = dropoff.landmarkId || '0'),
            (params.dlat = dropoff.lat),
            (params.dlng = dropoff.lng),
            (params.oplace = true);
        userSelect.dropoff = {
            ...dropoff,
            landmarkType: pickup.landmarkType || '',
        };
        userSelect.oPlace = true;
        userSelect.oplace = true;
    }

    if (queryParams.topic) {
        params.topic = queryParams.topic;
    }

    return {
        params,
        userSelect,
    };
}

export function initSearchParams({ userSelectlocalData }) {
    const now = DateUitl.formatPickDropNow();
    const defaultTime = formatDate();
    const searchFilter = {
        pickup: {
            pickTime: defaultTime.pickTime,
            address: defaultArea.address,
            cityCode: defaultArea.cityCode,
            cityName: defaultArea.cityName,
            wCityId: defaultArea.wCityId,
            landmarkName: defaultArea.address,
            landmarkType: defaultArea.landmarkType || '',
            lat: defaultArea.lat,
            lng: defaultArea.lng,
        },
        dropoff: {
            dropTime: defaultTime.dropTime,
            address: defaultArea.address,
            cityCode: defaultArea.cityCode,
            cityName: defaultArea.cityName,
            wCityId: defaultArea.wCityId,
            landmarkName: defaultArea.address,
            landmarkType: defaultArea.landmarkType || '',
            lat: defaultArea.lat,
            lng: defaultArea.lng,
        },
        useDefault: true,
    };
    let oplace = userSelectlocalData.oplace || userSelectlocalData.oPlace;

    if (!isEmptyObject(userSelectlocalData)) {
        let {
            pickup: { pickTime },
            dropoff: { dropTime },
        } = userSelectlocalData;
        // 对历史遗留的时间戳进行格式化
        if (!Number.isNaN(pickTime) && pickTime.toString().length <= 13) {
            // Number一次 确保毫秒级别能正确格式化
            userSelectlocalData.pickup.pickTime = DateUitl.format(
                Number(pickTime),
                'YYYY/MM/DD HH:mm'
            );
        }
        if (!Number.isNaN(dropTime) && dropTime.toString().length <= 13) {
            userSelectlocalData.dropoff.dropTime = DateUitl.format(
                Number(dropTime),
                'YYYY/MM/DD HH:mm'
            );
        }
        // 用户记录与当前时间比对
        const historyAfterNow = DateUitl.isAfter({
            start: now,
            end: DateUitl.format(pickTime, 'YYYY/MM/DD HH:mm'),
            unit: null,
        });
        // 取还车时间比对
        const diffPickDrop = DateUitl.parseDiff({
            start: pickTime,
            end: dropTime,
            unit: 'minute',
        });
        // 计算租期
        const { days } = DateUitl.diff({
            start: pickTime,
            end: dropTime,
        });
        searchFilter.pickup = {
            pickTime: defaultTime.pickTime,
            ...userSelectlocalData.pickup,
        };
        searchFilter.dropoff = {
            dropTime: defaultTime.dropTime,
            ...userSelectlocalData.dropoff,
        };
        searchFilter.useDefault = false;
        // 若历史记录时间早于当前时间，则取还车时间使用当前时间
        if (!historyAfterNow || diffPickDrop < 30 || days < 0) {
            searchFilter.pickup = {
                ...userSelectlocalData.pickup,
                pickTime: defaultTime.pickTime,
            };
            searchFilter.dropoff = {
                ...userSelectlocalData.dropoff,
                dropTime: defaultTime.dropTime,
            };
        }
    }

    return {
        searchFilter,
        oPlace: oplace,
        oplace,
    };
}

export function initActivitySearchParams(searchFilter) {
    let oPlace = false;
    const {
        pickTime,
        dropTime
    } = formatDate(
        searchFilter?.pickup?.time,
        searchFilter?.dropoff?.time
    );
    if (searchFilter.pickup) {
        searchFilter.pickup.pickTime = pickTime;
    }
    if (searchFilter.dropoff) {
        searchFilter.dropoff.dropTime = dropTime;
    }
    if (searchFilter?.pickup?.landmarkId &&
        searchFilter?.dropoff?.landmarkId &&
        searchFilter?.pickup?.landmarkId !== searchFilter?.dropoff?.landmarkId
    ) {
        oPlace = true;
    }
    return {
        searchFilter,
        oPlace
    };
}